<div class="container-fluid" style="margin-top: 1%;">
    <mat-card [tabindex]="0" class="card-principal">
      <div class="container-fluid">
          
          <h3>
            Cajas para Cirugías
          </h3>
       
          <div
            class="d-flex justify-content-center"
            style="margin-top: 10%"
            *ngIf="loading">
            <mat-spinner [diameter]="60"></mat-spinner>
            
        </div>
  
        <mat-card-content>
          
        <div  class="row" style="margin-top: 1%;  width: 100%;">
          <div [hidden]="loading" class="col" style="overflow: auto;">
          
            <div class="contenedor-utils">
                <mat-form-field *ngIf="!sinCajas">
                  <mat-label>Buscar</mat-label>
                  <input [(ngModel)]="buscarValue" matInput (keyup)="applyFilter($event)" autocomplete="off" class="buscar"/>
                </mat-form-field>
                <span style="margin-left:1%;"></span>

                <button 
                mat-raised-button
                class="agregar-button"
                (click)="crearCaja()"
                >
                Agregar nueva caja
                </button>
            </div>

            <div style="text-align: center; margin-top: 5%;  " *ngIf="sinCajas">
              <mat-card class="sin-cajas-card">
                <h5 style="color:#22507c" >No se encontraron cajas</h5>
              </mat-card>
            </div>
            
            <div class="contenedor">
              <div class="contenedor-tabla">
                <table
                  mat-table
                  [dataSource]="cajas"
                  #tablaCajas
                  class="mat-elevation-z8"
                  *ngIf="!sinCajas"
                >
                  <!-- Columna Nombre -->
                  <div style="text-align: center; margin-right: 1px">
                    <ng-container matColumnDef="nombre" class="mat-column-nombre">
                      <th mat-header-cell *matHeaderCellDef>Nombre</th>
                      
                      <td mat-cell *matCellDef="let caja">
                        {{ caja.nombre }} 
                      </td>
                     
                    </ng-container>
                  </div>
      
                  <!-- Columna Acciones -->
                  <div style="text-align: center;">
                    <ng-container matColumnDef="acciones" class="mat-column-acciones">
                      <th mat-header-cell *matHeaderCellDef style="height: fit-content;">Acciones:</th>
                      <td mat-cell *matCellDef="let caja" class="action-link" id="{{caja.idCaja}}">
      
                         <!-- Botón Modificar nombre -->
                         <button 
                            mat-raised-button
                            class="modificar-button"
                            (click)="modificarNombre(caja)"
                          >
                            <mat-icon>border_color</mat-icon>
                          </button>
      
                        <!-- Botón Eliminar 
                        <button 
                          mat-raised-button
                          class="eliminar-button" 
                          (click)="eliminarCaja(caja.idCaja)"
                        >
                         <mat-icon>highlight_off</mat-icon>
                        </button> -->
      
                        
                      </td>
                    </ng-container>
                  </div>
      
                  <tr mat-header-row *matHeaderRowDef="displayedColumnasItems; sticky: true"></tr>
                  <tr mat-row *matRowDef="let row; columns: displayedColumnasItems"></tr>
                </table>
              </div>
            </div>
  
          </div>
        </div>
      </mat-card-content>
  
      </div>
    </mat-card>
  </div>
