<h2 class="title">Cierres de Guardia</h2>

<div *ngIf="loading" class="loading"><div class="spinner"></div></div>
<div *ngIf="success" class="alert alert-success alert-dismissible fade show mb-4" role="alert" style="max-width: 500px; margin: 0 auto;">
  {{ mensaje }}
  <button type="button" class="close" (click)="closeAlert()" aria-label="Close">
    <span aria-hidden="true">&times;</span>
  </button>
</div>

<div *ngIf="error" class="alert alert-danger alert-dismissible fade show mb-4" role="alert" style="max-width: 500px; margin: 0 auto;">
  {{ mensaje }}
  <button type="button" class="close" (click)="closeAlert()" aria-label="Close">
    <span aria-hidden="true">&times;</span>
  </button>
</div>

<div *ngIf="errorGuardiaAct" class="alert alert-info alert-dismissible fade show mb-4" role="alert" style="max-width: 500px; margin: 0 auto;">
  {{ mensaje }}
  <button type="button" class="close" (click)="closeAlert()" aria-label="Close">
    <span aria-hidden="true">&times;</span>
  </button>
</div>

<div class="btn-container" *ngIf="!loading && !error && !primeraGuardia">
  <div *ngIf="ultimo.estado==='ACT'" class="cierre-guardia-activo-container">
    <h3 class="cierre-guardia-estado">GUARDIA CERRADA</h3>
    <div class="cierre-guardia-activo-div mb-4">
      <div class="cierre-guardia-activo-div">
        <div class="cga-header">
          <div class="cga-header-item">Desde</div>
          <div class="cga-header-item">Motivo</div>
        </div>
        <div class="cga-row">
          <div class="cga-row-item">{{ ultimo.fechaCarga | recortarFecha }}</div>
          <div class="cga-row-item" [innerHTML]="ultimo | mostrarMotivo"></div>
        </div>
      </div>
      <button class="cga-btn" (click)="editarMotivo(ultimo)" alt="Editar motivo">
        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-pencil-square" viewBox="0 0 16 16">
          <path d="M15.502 1.94a.5.5 0 0 1 0 .706L14.459 3.69l-2-2L13.502.646a.5.5 0 0 1 .707 0l1.293 1.293zm-1.75 2.456-2-2L4.939 9.21a.5.5 0 0 0-.121.196l-.805 2.414a.25.25 0 0 0 .316.316l2.414-.805a.5.5 0 0 0 .196-.12l6.813-6.814z"/>
          <path fill-rule="evenodd" d="M1 13.5A1.5 1.5 0 0 0 2.5 15h11a1.5 1.5 0 0 0 1.5-1.5v-6a.5.5 0 0 0-1 0v6a.5.5 0 0 1-.5.5h-11a.5.5 0 0 1-.5-.5v-11a.5.5 0 0 1 .5-.5H9a.5.5 0 0 0 0-1H2.5A1.5 1.5 0 0 0 1 2.5z"/>
        </svg>
        Editar
      </button>
    </div>
  <button (click)="finalizarCierre(ultimo)" class="btn btn-success"> Abrir Guardia </button>  
  </div>
  <div *ngIf="ultimo.estado==='INA'">
    <h3 class="cierre-guardia-estado">GUARDIA ABIERTA</h3>
    <button (click)="cerrarGuardia()" class="btn btn-warning"> Cerrar Guardia </button>
  </div>
</div>
<div *ngIf="!loading && !error && !primeraGuardia" class="historial-container">
  <h3 class="title">Historial de Cierres de Guardia</h3>
  <table class="table mb-4">
    <thead>
      <tr>
        <th>Desde</th>
        <th>Usuario cierra</th>
        <th>Hasta</th>
        <th>Usuario abre</th>
        <th>Motivo</th>
      </tr>
    </thead>
    <tbody>
      <tr *ngFor="let cierre of historialCierres">
        <td>{{ cierre.fechaCarga | recortarFecha }}</td>
        <td>{{ cierre.usuarioCarga }}</td>
        <td>{{ cierre.estado === 'ACT' ? 'Cierre de guardia activo' : (cierre.fechaHasta | recortarFecha) }}</td>
        <td>{{ cierre.estado === 'ACT' ? 'Cierre de guardia activo' : cierre.usuarioEstado }}</td>
        <td [innerHTML]="cierre | mostrarMotivo"></td> 
      </tr>
    </tbody>
  </table>
</div>
<div class="primer-cierre-container" *ngIf="primeraGuardia">
  <h3>No existen cierres de guardia registrados</h3>
  <button (click)="cerrarGuardia()" class="btn btn-warning"> Cerrar Guardia </button>
</div>
<app-guardias-cierre-modal *ngIf="modalVisible && !loading && !error" 
  [cierre]="cierreEditar"
  [editar]="editar"
  (motivoSeleccionado)="recibirMotivo($event)"
  (cerrarModal)="closeModal($event)">
</app-guardias-cierre-modal>