import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AppComponent } from '../app.component';
import { Observable } from 'rxjs';
import { log } from 'console';

interface Reserva {
  id: number;
  idCaja: number;
  nombreCaja: string;
  fechaHoraEstado: string;
  numeroCirugia: number;
  fechaCirugia: string;
  horaCirugia: string;
  instrumentador: string;
  cirujano: string;  
}

@Injectable({
  providedIn: 'root'
})
export class CajasEsterilizacionService {
  baseUrl: string;

  constructor(
    private http: HttpClient) {      
        this.baseUrl = AppComponent.baseUrl;
    }

    obtenerReservasPorFecha(fechaDesde, fechaHasta, idCaja:number, matriculaCirujano:number, paginaActual:number){
      const fechaDesde_Formateada = fechaDesde.toISOString().split('T')[0]; // Formatea la fecha como 'yyyy-MM-dd'
      const fechaHasta_Formateada = fechaHasta.toISOString().split('T')[0];

      // console.log('idCaja: '+idCaja);
      // console.log('matriculaCirujano: '+matriculaCirujano);
      
      
      // Agrega la fecha formateada a la URL de la solicitud
      return this.http.get<any>(
        this.baseUrl+'/cajasCirugias/reservas', 
        { params: { fechaDesde: fechaDesde_Formateada,
                    fechaHasta: fechaHasta_Formateada,
                    idCaja: idCaja ? idCaja : '',
                    matriculaCirujano: matriculaCirujano ? matriculaCirujano : '',
                    numeroPagina: paginaActual,
                  } 
        });    
    }

    obtenerTodasLasReservasPorFecha(fechaDesde, fechaHasta, idCaja:number, matriculaCirujano:number){
      const fechaDesde_Formateada = fechaDesde.toISOString().split('T')[0]; // Formatea la fecha como 'yyyy-MM-dd'
      const fechaHasta_Formateada = fechaHasta.toISOString().split('T')[0];

      // Agrega la fecha formateada a la URL de la solicitud
      return this.http.get<any>(
        this.baseUrl+'/cajasCirugias/todasLasReservas', 
        { params: { fechaDesde: fechaDesde_Formateada,
                    fechaHasta: fechaHasta_Formateada,
                    idCaja: idCaja ? idCaja : '',
                    matriculaCirujano: matriculaCirujano ? matriculaCirujano : '',
                  } 
        });    
    }

    obtenerCajas() {
      let res= this.http.get<any>(this.baseUrl+'/cajasCirugias/seguro/getCajasCirugias')
      return res;
    }

    modificarCaja(id, nombre, estado){
      let res= this.http.post<any>(this.baseUrl+'/cajasCirugias/seguro/crearModificarCaja', 
      { id: id, 
        nombre: nombre, 
        estado: estado});
      return res;
    }
   
    getCirugiasFiltro(filtrosCirugia) {
      return this.http.post<any>(this.baseUrl + '/cirugias/seguro/getCirugiasProgramadasFiltro', JSON.stringify(filtrosCirugia));
    }

    getInstrumentadores() {
      return this.http.get<any>(this.baseUrl + '/profesionales/seguro/getInstrumentadores');
    }

    getCajasDisponibles(filtrosCaja) {
      return this.http.post<any>(this.baseUrl + '/cajasCirugias/seguro/getCajasDisponiblesFecha', JSON.stringify(filtrosCaja));
    }

    getCajasCirugia(filtrosCirugia) {
      return this.http.post<any>(this.baseUrl + '/cajasCirugias/seguro/getCajasCirugia', JSON.stringify(filtrosCirugia));
    }

    setCajaCirugia(filtrosCaja) {
      //metodo que hace una baja lógica o una alta de caja cirugia dependiendo de los parámetros recibidos
      return this.http.post<any>(this.baseUrl + '/cajasCirugias/seguro/setCajaCirugiaProgramada', JSON.stringify(filtrosCaja));
    }

    modificarEstadoReservaCajas(filtrosReserva) {
      return this.http.post<any>(this.baseUrl + '/cajasCirugias/seguro/modificarReservaCirugia', JSON.stringify(filtrosReserva));
    }
}
