import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { CajasEsterilizacionService } from '../_services/cajas-esterilizacion.service';
import { PacienteService } from '../_services/paciente.service';
import { DialogConfirmacionComponent } from '../dialog-confirmacion/dialog-confirmacion.component';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatPaginatorIntl } from '@angular/material/paginator'; 


import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { DialogMensajeComponent } from '../dialog-mensaje/dialog-mensaje.component';
import { CustomMatPaginatorIntl } from '../_helpers/custom-mat-paginator-intl';
import * as XLSX from 'xlsx';

interface Reserva {
  id: number;
  idCaja: number;
  nombreCaja: string;
  fechaHoraEstado: string;
  numeroCirugia: number;
  fechaCirugia: string;
  horaCirugia: string;
  instrumentador: string;
  cirujano: string; 
  estado: string; 
  preparada: boolean;
}


@Component({
  selector: 'app-reserva-cajas',
  templateUrl: './reserva-cajas.component.html',
  styleUrls: ['./reserva-cajas.component.css'],
  providers: [
    { provide: MatPaginatorIntl, useClass: CustomMatPaginatorIntl }  // Aquí aplicamos el CustomMatPaginatorIntl solo a este componente
  ]
})
export class ReservaCajasComponent implements OnInit {
  dateControl_fechaDesde = new FormControl(new Date());
  dateControl_fechaHasta = new FormControl(new Date());
  reservas: Reserva[];
  todasLasreservas: Reserva[];
  columnas: string[] = ['fecha', 'hora', 'instrumentador', 'cirujano', 'caja', 'observaciones'];
  cargando: boolean = true;
  
  //cajas
  cajas;
  cajaSeleccionadaControl  = new FormControl(''); // Control para el select
  cajaSeleccionada = '';
  filtroCajasControl = new FormControl();
  cajasFiltradas;

  //cirujanos
  cirujanos;
  cirujanosFiltrados;
  cirujanoSeleccionadoControl = new FormControl(''); 
  cirujanoSeleccionado = '';
  nombreCirujanoSeleccionado;
  filtroApellido: string = '';  
  filtroApellidoControl = new FormControl();
  protected onDestroyProperty = new Subject<void>();

  //paginación
  paginasTotales: number; 
  paginaActual: number = 1; //la pagina del bloque
  registrosPorBloque: number; //el bloque de elementos que me traigo desde el sp (este es el que se pagina) 
  registrosTotales: number; 
  datosAMostrar;
  pageIndexInterno :number = 0; //indice 
  pageSizeInterno :number = 5; //tamaño de pag del front. (dejar en 5)



  @ViewChild('inputCirujano') inputCirujano: ElementRef;

  constructor(
    private cajasEsterilizacionService: CajasEsterilizacionService,
    private pacienteService: PacienteService,
    public dialog: MatDialog,
  ) { }

  async ngOnInit() {
    await this.loadReservas(this.cajaSeleccionada, this.cirujanoSeleccionado); // Cargar reservas al inicio
    await this.obtenerCajas();
    await this.obtenerProfesionales();
    await this.suscribirseAEventos();    
  }

  suscribirseAEventos() {
    this.dateControl_fechaDesde.valueChanges.subscribe(() => {
      this.limpiarParaPaginacion();
      this.loadReservas(this.cajaSeleccionada, this.cirujanoSeleccionado); // Cargar reservas cuando cambie la fecha
    });

    this.dateControl_fechaHasta.valueChanges.subscribe(() => {
      this.limpiarParaPaginacion();
      this.loadReservas(this.cajaSeleccionada, this.cirujanoSeleccionado); // Cargar reservas cuando cambie la fecha
    });

    // Suscribirte a los cambios del select de las cajas
    this.cajaSeleccionadaControl.valueChanges.subscribe(
      idCajaSeleccionada => {
        this.limpiarParaPaginacion();
        this.loadReservas(idCajaSeleccionada, this.cirujanoSeleccionado);
        this.cajaSeleccionada = idCajaSeleccionada;
    });

    this.cirujanoSeleccionadoControl.valueChanges.subscribe(
      idCirujanoSeleccionado => {
        this.limpiarParaPaginacion();
        // Buscar el cirujano correspondiente en la lista
        // console.log(idCirujanoSeleccionado);
        this.loadReservas(this.cajaSeleccionada, idCirujanoSeleccionado );
        this.cirujanoSeleccionado = idCirujanoSeleccionado;
        if(this.cirujanoSeleccionadoControl.value){
          this.nombreCirujanoSeleccionado = this.cirujanos.find(c => c.nroMatricula == idCirujanoSeleccionado).apellido;        
        }else{
          this.nombreCirujanoSeleccionado = '';
        }
      }
    );
    
    this.filtroApellidoControl.valueChanges
        .pipe(takeUntil(this.onDestroyProperty))
        .subscribe(() => {
          this.filtroCirujanos();          
        });    

    this.filtroCajasControl.valueChanges
    .pipe(takeUntil(this.onDestroyProperty))
    .subscribe(() => {
      this.filtroCajas(); // Función para filtrar las cajas
    });
  }

  async obtenerProfesionales() {
    await this.pacienteService.getProfesional(null, null, null, null, 'ON').toPromise().then(
      data => {        
        this.cirujanos = data.paginador.elementos;        
        this.cirujanos.sort((a, b) => a.apellido.localeCompare(b.apellido));
        this.cirujanosFiltrados = this.cirujanos;
      },
      error => {
        console.log(error);        
      }
    )  }

  async obtenerCajas() {
    await this.cajasEsterilizacionService.obtenerCajas().toPromise().then(
      data => {        
        this.cajas = data;
        this.cajas.sort((a, b) => a.idCaja.localeCompare(b.idCaja));  
        this.cajasFiltradas = this.cajas;    
      },
      error => {
        console.log(error);        
      }
    );
  }

  filtroCirujanos(){
    if (!this.cirujanos) {
      return;
    }
    let search = this.filtroApellidoControl.value;
    
    if (!search) {
      this.cirujanosFiltrados = this.cirujanos;
      
      return;
    } else {
      search = search.toLowerCase();
    }
    this.cirujanosFiltrados =
      this.cirujanos.filter(
        (medicos) => medicos.apellido.toLowerCase().indexOf(search) > -1 || medicos.nroMatricula.toLowerCase().indexOf(search) > -1
      );
  }

  filtroCajas() {
    let search = this.filtroCajasControl.value;
    
    if (!search) {
      this.cajasFiltradas = this.cajas;
      return;
    } else {
      search = search.toLowerCase();
    }
    this.cajasFiltradas =
      this.cajas.filter(
        (caja) => caja.nombre.toLowerCase().indexOf(search) > -1
      );
  } 

  previousDay_fechaDesde() {
    this.cargando = true;
    const currentDate = this.dateControl_fechaDesde.value;
    currentDate.setDate(currentDate.getDate() - 1);
    this.dateControl_fechaDesde.setValue(currentDate);
  }

  nextDay_fechaDesde() {
    this.cargando = true;
    const currentDate = this.dateControl_fechaDesde.value;
    currentDate.setDate(currentDate.getDate() + 1);
    this.dateControl_fechaDesde.setValue(currentDate);
  }

  previousDay_fechaHasta() {
    this.cargando = true;
    const currentDate = this.dateControl_fechaHasta.value;
    currentDate.setDate(currentDate.getDate() - 1);
    this.dateControl_fechaHasta.setValue(currentDate);
  }

  nextDay_fechaHasta() {
    this.cargando = true;
    const currentDate = this.dateControl_fechaHasta.value;
    currentDate.setDate(currentDate.getDate() + 1);
    this.dateControl_fechaHasta.setValue(currentDate);
  }

  async loadTodasLasReservas(idCaja, matriculaCirujano) {
    const selectedDate_fechaDesde = this.dateControl_fechaDesde.value;
    const selectedDate_fechaHasta = this.dateControl_fechaHasta.value;
    // Realiza una solicitud al backend para cargar las reservas para la fecha seleccionada para exportar a excel
    await this.fetchAllReservasFromBackend(selectedDate_fechaDesde, selectedDate_fechaHasta, idCaja, matriculaCirujano, this.paginaActual);
  }
  async loadReservas(idCaja, matriculaCirujano) {
    const selectedDate_fechaDesde = this.dateControl_fechaDesde.value;
    const selectedDate_fechaHasta = this.dateControl_fechaHasta.value;
    // Realiza una solicitud al backend para cargar las reservas para la fecha seleccionada paginadas
    await this.fetchReservasFromBackend(selectedDate_fechaDesde, selectedDate_fechaHasta, idCaja, matriculaCirujano, this.paginaActual);
  }

  async fetchReservasFromBackend(fechaDesde: Date, fechaHasta: Date, idCaja, matriculaCirujano, paginaActual){
    // Actualiza this.reservas con los datos cargados desde el backend
    this.cargando = true
    await this.cajasEsterilizacionService.obtenerReservasPorFecha(
      fechaDesde, 
      fechaHasta, 
      idCaja, 
      matriculaCirujano,
      paginaActual,
    )
      .toPromise().then(response => {
        if(response.ok){
          //console.log(response)
          this.reservas = this.reservas || []; 
          if (!this.reservas) {
            this.reservas = [];
          }
          this.reservas.push(...(response.elementos || []));
          //console.log(this.reservas == null);
          //console.log(this.reservas );
          this.paginaActual = response.paginaActual;
          this.registrosPorBloque = response.registrosPorBloque;
          this.registrosTotales = response.registrosTotales;
          this.paginasTotales = Math.ceil(this.registrosTotales / this.pageSizeInterno); 
          this.datosAMostrar = this.reservas.slice(this.pageIndexInterno, this.pageSizeInterno);

        }else{
          this.reservas = null;
        }
        this.cargando = false;        
      });
  }
  async fetchAllReservasFromBackend(fechaDesde: Date, fechaHasta: Date, idCaja, matriculaCirujano, paginaActual){
    this.cargando = true
    await this.cajasEsterilizacionService.obtenerTodasLasReservasPorFecha(
      fechaDesde, 
      fechaHasta, 
      idCaja, 
      matriculaCirujano,
    )
      .toPromise().then(response => {
        if(response.ok){
          //console.log(response.ok)
          this.todasLasreservas = response.elementos; 
          //console.log(this.todasLasreservas)
          this.configuracionesExcel();
          
        }else{
          this.todasLasreservas = null;
        }
        this.cargando = false;        
      });
  }

  async onPageChangedInterno(event) {
    const pageIndex = event.pageIndex;
    const pageSize = event.pageSize;
  
    //calcula los registros a mostrar según el número de pag interna
    const startIndex = pageIndex * pageSize;
    const endIndex = startIndex + pageSize;

    if (endIndex > this.reservas.length && this.reservas.length < this.registrosTotales) {
      this.paginaActual += 1 //la pag del bloque con los registros que me traigo desde el sp
      await this.loadReservas(this.cajaSeleccionada, this.cirujanoSeleccionado);
    } 

    this.datosAMostrar = this.reservas.slice(startIndex, endIndex);  //muestra solo los registros para esa pag interna
    this.pageIndexInterno = pageIndex;  //actualiza la pag interna

  }

  limpiarParaPaginacion() {
    this.reservas = [];
    this.datosAMostrar = []
    this.paginaActual = 1
    this.pageIndexInterno = 0;
  }

  limpiarFiltroCaja() {
    this.cajaSeleccionadaControl.setValue('');
  }

  limpiarFiltroCirujano(){
    this.cirujanoSeleccionadoControl.setValue('');
  }

  actualizarEstado(reserva){
    reserva.estado === 'ACT' ? reserva.estado= 'PRP' : reserva.estado= 'ACT';
    this.dialog
      .open(DialogConfirmacionComponent, {
        data: `¿Desea modificar el estado de la reserva?`,
      })
      .afterClosed()
      .subscribe((confirmado: boolean) => {
        if (confirmado) {
          this.cajasEsterilizacionService.modificarEstadoReservaCajas({
            numeroCirugia: reserva.numeroCirugia,
            observaciones: null,
            estado: reserva.estado,
          }).toPromise().then(x => {
            this.dialog.open(DialogMensajeComponent, {
              width: '500px',
              data: x,
              }
            )
          })
        } else {
          reserva.estado === 'ACT' ? reserva.estado= 'PRP' : reserva.estado= 'ACT';
        }
      });

  }

  async exportarAExcel(){
    await this.loadTodasLasReservas(this.cajaSeleccionada, this.cirujanoSeleccionado);
  }

  configuracionesExcel(){

    const jsonFiltrado = this.todasLasreservas.map((reserva: any) => {
      const cajasReservadas = reserva.cajasReservadas ? reserva.cajasReservadas.map(caja => caja.nombreCaja).join(', ') : '';
      return {
        "Número de cirugia": reserva.numeroCirugia,
        "Fecha": reserva.fechaCirugia,
        "Hora": reserva.horaCirugia,
        "Cirujano": reserva.cirujano,
        "Instrumentador": reserva.instrumentador,
        "Observaciones": reserva.observaciones,
        "Estado": reserva.estado == 'PRP' ? "Reservada" : "No reservada",
        "Cajas Reservadas": cajasReservadas,
      };
    });
    //console.log(jsonFiltrado);
    const ws = XLSX.utils.json_to_sheet(jsonFiltrado);
    const wb = XLSX.utils.book_new(); 
    XLSX.utils.book_append_sheet(wb, ws, 'Datos');  

    const colWidths = [
      { wpx: 90 }, 
      { wpx: 90 },
      { wpx: 90 }, 
      { wpx: 190 }, 
      { wpx: 190 }, 
      { wpx: 200 }, 
      { wpx: 90 }, 
      { wpx: 180 }, 
    ];
    ws['!cols'] = colWidths;
    const desde_formateado = new Intl.DateTimeFormat('es-ES').format(this.dateControl_fechaDesde.value).replace(/\//g, '-');
    const hasta_formateado = new Intl.DateTimeFormat('es-ES').format(this.dateControl_fechaHasta.value).replace(/\//g, '-');
    XLSX.writeFile(wb, 'Listado de reservas desde '+ desde_formateado + ' hasta ' + hasta_formateado +'.xlsx'); 
  }
}
