import { Component, OnInit, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { UntypedFormGroup, Validators, UntypedFormBuilder, FormControl, AbstractControl, ValidatorFn, ValidationErrors } from '@angular/forms';
import { InternacionService } from '../_services/internacion.service';
import { AuthenticationService } from '../_services/authentication.service';
import { CamasService } from '../_services/camas.service';
import * as moment from 'moment';
import { DialogConfirmacionComponent } from '../dialog-confirmacion/dialog-confirmacion.component';
import { MatDialog } from '@angular/material/dialog';
import { CajasEsterilizacionService } from '../_services/cajas-esterilizacion.service';
import { MatTableDataSource } from '@angular/material/table';
import { DialogMensajeComponent } from '../dialog-mensaje/dialog-mensaje.component';
import { FormGroup } from '@angular/forms';

@Component({
  selector: 'app-dialog-asignacion-caja',
  templateUrl: './dialog-asignacion-caja.component.html',
  styleUrls: ['./dialog-asignacion-caja.component.css']
})
export class DialogAsignacionCajaComponent implements OnInit {
  filtroBusquedaForm: FormGroup;
  observacionesForm: FormGroup;
  submitted = false;
  loadingCajasCirugia: boolean = true;
  loadingCajasDisponibles: boolean = true;
  cajasCirugia;
  cajasDisponibles: any[];
  currentUser;
  paciente;
  hayCajasCirugia: boolean;
  hayCajasDisponibles: boolean;
  errorCajasCirugia: boolean;
  errorCajasDisponibles: boolean;
  dataSourceItems;
  cajasReservadas: any[];
  observacion: String;
  displayedColumnasItems: string[] = ['nombre', 'fechaEstado', 'action'];
  fechaPosterior: boolean = true;
  camas: any[];
  cajas: any[];
  loadingColumns: boolean = true;
  tieneInstrumentista: boolean;
  cleanedForm: boolean = false;
  auxi: boolean = false;

  constructor(
    public dialog: MatDialogRef<DialogConfirmacionComponent>,
    public dialogo: MatDialog,
    private formBuilder: UntypedFormBuilder,
    private cajasService: CajasEsterilizacionService,
    @Inject(MAT_DIALOG_DATA) public data
  ){
    this.filtroBusquedaForm = this.formBuilder.group({
      idCaja: [null]
    });
    }

  async ngOnInit() {
    this.tieneInstrumentista = this.data.instrumentista != "";
    this.loadingCajasCirugia = true;
    this.calcularFecha();
    this.getCajasCirugia();
    this.getCajasDisponibles();
  }

  private buildFiltroBusquedaForm() {
    this.filtroBusquedaForm = this.formBuilder.group({
        idCaja: [null]
    });
  }

  private buildObservacionesForm() {
    this.observacionesForm = this.formBuilder.group({
      observaciones: [this.observacion, [Validators.maxLength(250), this.noSpecialCharactersValidator()]]

    });
  }

  async getCajasCirugia() {
    let filtrosCirugia = {"numero": this.data.numero}
    await this.cajasService.getCajasCirugia(filtrosCirugia).toPromise().then(
      (data) => {
        if (data.ok) {
          if (typeof data.elementos !== 'undefined') {
            this.cajasCirugia = data.elementos[0].cajasReservadas;
            this.observacion = data.elementos[0].observaciones;
            for (const clave in data.elementos[0].cajasReservadas) {
              if (data.elementos[0].cajasReservadas.hasOwnProperty(clave)) {
                  const elemento = data.elementos[0].cajasReservadas[clave];
                  const fechaOriginal = new Date(elemento.fechaHoraEstado);
                  const fechaFormateada = fechaOriginal.toLocaleDateString('es-ES', {
                      day: '2-digit',
                      month: '2-digit',
                      year: 'numeric',
                      hour: '2-digit',
                      minute: '2-digit',
                      second: '2-digit'
                  });
      
                  elemento.fechaHoraEstado = fechaFormateada;
              }
            }
            this.cajasReservadas = data.elementos[0].cajasReservadas;
            this.dataSourceItems = new MatTableDataSource(data.elementos);
            this.hayCajasCirugia = true;
            this.buildObservacionesForm();
          } else {
            this.observacionesForm = this.formBuilder.group({
              observaciones: null,
            })
            this.hayCajasCirugia = false;
          }
        } else {
          this.errorCajasCirugia = true;
          this.observacionesForm = this.formBuilder.group({
            observaciones: null,
          })
        }
        this.loadingCajasCirugia = false;
      },
      (error) => {
        console.log(error);
      } 
    )
  }

  async getCajasDisponibles() {
    let filtro = {'fechaCirugia': this.data.fecha};
    await this.cajasService.getCajasDisponibles(filtro).toPromise().then(
      (data) => {
        if (data.ok) {
          if (data.elementos.length > 0) {
            this.hayCajasDisponibles = true;
            this.cajasDisponibles = data.elementos;
            this.cajasDisponibles.sort((a, b) => {
              const nombreA = a.nombreCaja.toUpperCase();
              const nombreB = b.nombreCaja.toUpperCase();
              if (nombreA < nombreB) {
                  return -1;
              }
              if (nombreA > nombreB) {
                  return 1;
              }
              return 0;
          });
          } else {
            this.hayCajasDisponibles = false;
          }
        } else {
          this.errorCajasDisponibles = true;
        }
        this.loadingCajasDisponibles = false;
      },
      (error) => {
        console.log(error);
      }
    )
    this.buildFiltroBusquedaForm();
  }

  calcularFecha() {
    let fechaActual = new Date();

    let fechaCirugia = this.data.fecha;

    fechaCirugia = fechaCirugia.split('/');
    var dia = parseInt(fechaCirugia[0], 10);
    var mes = parseInt(fechaCirugia[1], 10) - 1; 
    var año = parseInt(fechaCirugia[2], 10);

    fechaCirugia = new Date(año, mes, dia);
    if (fechaActual <= fechaCirugia) {
      this.fechaPosterior = true;
    }
    if (!this.fechaPosterior) {
      this.displayedColumnasItems = ['idCaja', 'nombre', 'fechaEstado'];
    }
    this.loadingColumns = false;
  }

  async confirmadoDesasignado(caja) {
    let filtros = {"id": caja.id, "usuario": this.data.usuario, "idCaja": null, "numeroCirugia": null};
    await this.cajasService.setCajaCirugia(filtros).toPromise().then(
      (data) => {
        if (data.ok) {
          this.dialogo.open(DialogMensajeComponent,{
            width: '500px',
            data:{
              ok: true,
              mensaje: "La caja se ha desasignado correctamente."
            }
          });
        } else {
          this.dialogo.open(DialogMensajeComponent,{
            width: '500px',
            data:{
              ok: false,
              mensaje: "Ocurrió un error al desasignar la caja."
            }
          });
        }
        
      },
      (error) => {
        console.log(error);
      }
    );
    this.getCajasDisponibles();
    this.getCajasCirugia();
    this.buildFiltroBusquedaForm();
  }

  async confirmadoAsignado(caja) {
    let filtros = {"id": null, "usuario": this.data.usuario, "idCaja": caja.idCaja, "numero": this.data.numero, "observaciones": null};
    await this.cajasService.setCajaCirugia(filtros).toPromise().then(
      (data) => {
        if (data.ok) {
          this.dialogo.open(DialogMensajeComponent,{
            width: '500px',
            data:{
              ok: true,
              mensaje: "La caja se ha asignado correctamente."
            }
          });
        } else {
          this.dialogo.open(DialogMensajeComponent,{
            width: '500px',
            data:{
              ok: false,
              mensaje: "Ocurrió un error al asignar la caja."
            }
          });
        }
      },
      (error) => {
        console.log(error);
      }
    )

    this.buildFiltroBusquedaForm();
    this.getCajasCirugia();
    this.getCajasDisponibles();
  }

  desasignarCaja(caja) {
    this.dialogo.open(DialogConfirmacionComponent, {
      width: '500px',
      height: '190px',
      data: '¿Está seguro/a de que desea desasignar la caja "' + caja.nombreCaja + '"?',
    }).afterClosed()
    .subscribe(
      (confirmado: boolean) => {
        if (confirmado) {
          this.confirmadoDesasignado(caja);
        } 
      },
      (error) => {
        console.log(error);
      }
    );
  }

  async asignarCaja(caja) { 
    let nombreCaja = this.cajasDisponibles.find(function(cajaDisponible) {
      return cajaDisponible.idCaja === caja.idCaja;
    });

    const confirmado = await this.dialogo.open(DialogConfirmacionComponent, {
      width: '500px',
      height: '190px',
      data: '¿Está seguro/a de que desea asignar la caja "' + nombreCaja.nombreCaja + '"?',
    }).afterClosed().toPromise();
  
    if (confirmado) {
      try {
        await this.getCajasDisponibles();
        const cajaDisponibleAun = this.cajasDisponibles.find(
          (cajaDisponible) => cajaDisponible.idCaja === caja.idCaja
        );

        if (cajaDisponibleAun) {
          this.confirmadoAsignado(caja);
        } else {
          this.dialogo.open(DialogMensajeComponent,{
            width: '500px',
            data:{
              ok: false,
              mensaje: "La caja ya ha sido seleccionada en otra operación. Por favor, seleccione otra."
            }
          });
        }
      } catch (error) {
        console.log('Error al actualizar las cajas disponibles:', error);
      }
    }
  }
  

  cerrarPopup() {
    this.dialog.close();
  }

  resetearForm() {
    this.cleanedForm = true;
    this.observacionesForm.reset();
  }

  async cambiarObservacion(observacion) {
    //Muestra popup de confirmación e inicializa los filtros para guardar la observación en caso de aceptar el dialogo
    this.dialogo.open(DialogConfirmacionComponent, {
      width: '500px',
      height: '190px',
      data: '¿Está seguro/a de que desea editar la observación?'
    }).afterClosed()
    .subscribe(
      (confirmado: boolean) => {
        if (confirmado) {
          if (observacion.observaciones == null)
            observacion.observaciones = "";
          let filtros = {"id": null, "usuario": this.data.usuario, "idCaja": null, "numero": this.data.numero, "observaciones": observacion.observaciones};
          this.observacionConfirmada(filtros);
        } 
      },
      (error) => {
        console.log(error);
      }
    );
  }    

  async observacionConfirmada(filtros) {
    await this.cajasService.setCajaCirugia(filtros).toPromise().then(
      (data) => {
        if (data.ok) {
          this.dialogo.open(DialogMensajeComponent,{
            width: '500px',
            data:{
              ok: true,
              mensaje: "La observación se ha modificado correctamente."
            }
          });
          this.cleanedForm = false;
          this.observacionesForm = this.formBuilder.group({
            observaciones: filtros.observaciones,
          });
        } else {
          this.dialogo.open(DialogMensajeComponent,{
            width: '500px',
            data:{
              ok: false,
              mensaje: "Ocurrió un error al modificar la observación."
            }
          });
        }
      },
      (error) => {
        console.log(error);
      }
    )
  }

  // Validador para el campo Observaciones
  noSpecialCharactersValidator(): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      const forbidden = /[@#$%^&*()":{}|<>]/.test(control.value);
      return forbidden ? { 'specialCharacters': { value: control.value } } : null;
    };
  }

}
