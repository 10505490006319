<div class="row" >
    <div class="col">
        <h2 mat-dialog-title style="padding-left: 0%;padding-right: 0.5%;">Cajas asignadas</h2>
    </div>
    <div class="col">
        <button mat-raised-button class="cerrar-popup" (click)="cerrarPopup()">
            <mat-icon>clear</mat-icon>
        </button>
    </div>
</div>


<div mat-dialog-content>

  <div class="dialog-info">
    Instrumentador: <b>{{this.data.instrumentista ? this.data.instrumentista : 'No asignado'}}</b>
  </div>
  <div class="dialog-info">
    Fecha: <b>{{this.data.fecha}}</b>
  </div>
  <div class="dialog-info">
    Hora inicio: <b>{{this.data.horaInicio.slice(0,-3)}}</b>
  </div>
  <div class="dialog-info" style="margin-bottom: 2%;">
    Hora fin: <b>{{ this.data.horaFin.slice(0,-3) }}</b>  
  </div>

  <div *ngIf="!tieneInstrumentista">
    <div style="text-align: center; margin-top: 5%; margin-bottom: 2%;" >
      <mat-card class="sin-elementos-card">
        <h5 style="color:#d30225">La cirugía debe tener un instrumentista asignado para poder asignar/desasignar cajas a la misma.</h5>
      </mat-card>
    </div>

  </div>

  <mat-divider class="divisor" style="margin-top: 5%;"></mat-divider> 
 
    <span >
      <h6>
        Seleccionar caja
        <span *ngIf="!this.data.reservaValida" style="color: red;">
          (No se puede asignar porque la reserva debe realizarse con dos días de anticipación)
        </span>
      </h6>
    </span>

    <div style="text-align: center; margin-top: 5%;" [hidden]="!loadingCajasCirugias" *ngIf="!hayCajasDisponibles">
      <mat-card class="sin-elementos-card">
        <h5 style="color:#3F51B5">No hay cajas disponibles para la fecha</h5>
      </mat-card>
    </div>

    <div class="container-fluid" *ngIf="!loadingCajasDisponibles || !hayCajasDisponibles">
        <form [formGroup]="filtroBusquedaForm">
            <div class="row">
              <div class="col-xl-6 col-lg-6 col-md-6 col-sm-12 col-xs-12" id="cajas-disponibles">
                <mat-form-field style="width: 100%;">
                  <mat-label>Cajas disponibles</mat-label>
                  <mat-select  formControlName="idCaja">
                      <mat-option *ngFor="let caja of this.cajasDisponibles" [value]="caja.idCaja">
                          {{ caja.nombreCaja | toTitleCase }}
                      </mat-option>
                  </mat-select>
                </mat-form-field>
              </div>
              <br>
              <div class="col-xl-6 col-lg-6 col-md-6 col-sm-12 col-xs-12" id="asignar-caja-button">
                <button mat-raised-button 
                  color="primary" 
                  (click)="asignarCaja(filtroBusquedaForm.value)" 
                  [disabled]="filtroBusquedaForm.pristine || !fechaPosterior || !tieneInstrumentista || !this.data.reservaValida"
                  matTooltip="No puede asignar la caja porque la reserva debe realizarse con dos días de anticipación"
                  [matTooltipDisabled]="this.data.reservaValida">
                  Asignar caja
                </button>
              </div>
            </div>
        </form>
    </div>

    <div>
      <mat-divider class="divisor"></mat-divider> 
      <span>
        <h6>
          Cajas asignadas
        </h6>
      </span>

      <div style="text-align: center; margin-top: 5%;" [hidden]="loadingCajasCirugia" *ngIf="!hayCajasCirugia">
        <mat-card class="sin-elementos-card">
          <h5 style="color:#3F51B5">La cirugía no tiene cajas asignadas</h5>
        </mat-card>
      </div>
      
      <div class="contenedor-cards-simil-tabla">
        <div class="card-simil-tabla" *ngFor="let caja of cajasReservadas" [hidden]="!hayCajasCirugia">
          <div class="contenedor-detalle-card">
            <span><strong>{{caja.nombreCaja}}</strong></span>
            <span id="fecha-span">Asignada el: <strong> {{caja.fechaHoraEstado.slice(0,-3)}}</strong></span>
            <span>
              <button 
                mat-raised-button
                class="desasignar-button"
                (click)="desasignarCaja(caja)"
                [disabled]="!tieneInstrumentista"
              >
                <span >Desasignar</span>

              </button></span>
            
          </div>
        </div>
      </div>
    </div>

      <mat-divider class="divisor"  *ngIf="hayCajasCirugia"></mat-divider> 
      
      <!-- Observaciones -->
      <span *ngIf="hayCajasCirugia">
        <h6>
          Observaciones
        </h6>
      </span>
      <div class="container-fluid" id="observaciones-row">
        <div class="row">
          <div class="col-xl-6 col-lg-6 col-md-6 col-sm-12 col-xs-12" id="observaciones" style="margin-top: 2%;" *ngIf="hayCajasCirugia">
            <form [formGroup]="observacionesForm" [hidden]="!hayCajasCirugia">
              <mat-form-field >
                <textarea class="texto-observaciones" matInput type="text" maxlength="250" formControlName="observaciones"></textarea>
              </mat-form-field>
              <div *ngIf="observacionesForm.get('observaciones').hasError('specialCharacters')">
                <mat-error>El campo no debe contener símbolos especiales.</mat-error>
              </div>
              <button mat-icon-button 
              color="warn" 
              (click)="resetearForm()" 
              [hidden]="!hayCajasCirugia"
              [disabled]="!tieneInstrumentista"
            >
                   
              <mat-icon>delete</mat-icon>
            </button>
            </form>        
          </div>

          <div class="col-xl-6 col-lg-6 col-md-6 col-sm-12 col-xs-12" id="contenedor-modificar-observaciones" *ngIf="hayCajasCirugia">
            <div *ngIf="hayCajasCirugia">
              <button mat-raised-button 
                color="primary"  id="div-modificar-observacion"
                style="display: flex !important;"
                (click)="cambiarObservacion(observacionesForm.value)" 
                [disabled]="(observacionesForm.pristine && !cleanedForm) || !fechaPosterior || !tieneInstrumentista 
                || observacionesForm.get('observaciones').hasError('specialCharacters')"         
              >
                Modificar observación
              </button>
            </div>
          </div>
          </div>
        </div>  
      
  </div>

