import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { BehaviorSubject, Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { User } from '../_models/user.model';
import { Router } from '@angular/router';
import { AppComponent } from '../app.component';
import * as moment from 'moment';
const httpOptions = {
    headers: new HttpHeaders({})
  };
  
  @Injectable({ providedIn: 'root' })

  export class TriageService {

    baseUrl: string;



    private currentPatientSubject: BehaviorSubject<any>;

    public currentPatient: Observable<any>;

    constructor(
        private http: HttpClient,
        private router: Router) {
            
            this.baseUrl = AppComponent.baseUrl;
            this.currentPatientSubject = new BehaviorSubject<any>(JSON.parse(sessionStorage.getItem('currentPatient')))
            this.currentPatient = this.currentPatientSubject.asObservable()
            
    }
  
 
 
 
 
 //filtro Triage
  getTriagesPorFiltro(formTriage) {
    if(formTriage.subCategoria.prioridad.idPrioridad == '4'){
      formTriage.subCategoria.prioridad.idPrioridad= null;
    }
    return this.http.post<any>(this.baseUrl + '/triage/seguro/getTriagePorFiltro',formTriage);
     }


crearSignosVitales(
  presionSis,
  presionDias,
  pulsoCardiaco,
  frecRespiratoria,
  tempCorporal,
  saturacion,
  // catTriage,
  // subcatTriage,
  hora,
  fecha
 ) {
  const body = {
    presionSis: presionSis,
    presionDias:presionDias,
    pulsoCardiaco:pulsoCardiaco,
    frecRespiratoria:frecRespiratoria,
    tempCorporal:tempCorporal,
    saturacion:saturacion,
    // catTriage:catTriage,
    // subcatTriage:subcatTriage,
    hora:hora,
    fecha:fecha
  
  };
 {
  return this.http.post<any>(
    this.baseUrl + '/triage/seguro/crear_signosVitales',
    body,
    httpOptions
  );
  }
}

cargarCategorias(){
    return this.http.get<any>(this.baseUrl + '/triage/getTipoCategoria');
  }
  cargarSubCategorias(idCategoria){
    return this.http.get<any>(this.baseUrl + '/triage/getTipoSubCategoria/'+ idCategoria);
  }
  cargarPrioridades(){
    return this.http.get<any>(this.baseUrl + '/triage/getPrioridadTriage');
    }
    
  finTriage(impresionForm){
    // console.log('Form que llega al finTriage:',impresionForm)
    const body = {
      
      paciente: {
        nroDocumento:impresionForm.paciente.nroDocumento,
        tipoDocumento:impresionForm.paciente.tipoDocumento,
      },
      codigoServicio: impresionForm.codigoServicio,
      fechaIngreso: impresionForm.fecha,
      horaIngreso: impresionForm.hora,
      categoria: {
        nombre:impresionForm.catTriage.nombre,
        id:impresionForm.catTriage.id
      },
      subCategoria: {
        nombreSubCat:impresionForm.subcatTriage.nombreSubcat,
        idSubCat: impresionForm.subcatTriage.idSubCat,
        prioridad:{
          id: impresionForm.subcatTriage.prioridad.idPrioridad
        }
      },
      presionSistolica: impresionForm.presionSis,
      presionDiastolica:impresionForm.presionDias,
      pulsoCardiaco:impresionForm.pulsoCardiaco,
      frecuenciaRespiratoria:impresionForm.frecRespiratoria,
      temperatura:impresionForm.tempCorporal,
      saturacion:impresionForm.saturacion
  
    };
    {
      // console.log(body)
      return this.http.post<any>(
        this.baseUrl + '/triage/seguro/crear_pacienteTriage',body, httpOptions
      );
    }
  
  }
  setCurrentSignosFormLocal(impresionInicial){
    // console.log(impresionInicial)
    localStorage.setItem('signosActual', JSON.stringify(impresionInicial.value));
   
  }
  getCurrentSignosFormLocal(){
   
    return JSON.parse(localStorage.getItem('signosActual'));
  }

  cargarServicios(){
    return this.http.get<any>(this.baseUrl + '/triage/getServicioDerivacion');
  
  }
  
  cargarArribos(){
    return this.http.get<any>(this.baseUrl + '/triage/getModoArribo');
  
  }
  cambiarEstado(paciente){
    // console.log(paciente);
    const body = {
      paciente:{
        nroDocumento:paciente.paciente.nroDocumento,
        tipoDocumento: paciente.paciente.tipoDocumento
      },
      idTriage: paciente.idTriage,
      estado:{
        estado:paciente.estado.estado,
        desc: paciente.estado.desc
  
      },
      color:{
        idColor: paciente.color.idColor,
        nombre: paciente.color.nombre
      },
      servicio:{
        id: paciente.servicio.id,
        nombre: paciente.servicio.nombre
      }
    }
    // console.log("cambiarEstado:",body);
  return this.http.post<any>(this.baseUrl + '/triage/setEstadoOPrioridadAtencion', body);
  
  }

  cambiarPrioridad(triage, prioridad){
    const body = {
      paciente:{
        nroDocumento:triage.paciente.nroDocumento,
        tipoDocumento: triage.paciente.tipoDocumento
      },
      idTriage: triage.idTriage,
      estado: null,
      color:{
        idColor: prioridad.idPrioridad,
        nombre: prioridad.color
      },
      servicio:{
        id: triage.servicio.id,
        nombre: triage.servicio.nombre
      }
    }
  return this.http.post<any>(this.baseUrl + '/triage/setEstadoOPrioridadAtencion', body);
  
  }

  getEstadosTriage(){
    return this.http.get<any>(this.baseUrl + '/triage/getEstadosTriage');
  }  
 }