import { Component, OnInit } from '@angular/core';
import { CierresGuardiaService } from '../_services/cierres-guardia.service'
import * as moment from 'moment';
import { GuardiasCierreModalComponent } from '../guardias-cierre-modal/guardias-cierre-modal.component';
import { Motivo } from '../guardias-cierre-modal/guardias-cierre-modal.component';
import { ChangeDetectorRef } from '@angular/core';

export interface CierreGuardia {
  id: number;
  fechaDesde: string;
  fechaHasta: string;
  fechaCarga: string;
  estado: string;
  motivoDescripcion: string;
  motivo: Motivo;
}

@Component({
  selector: 'app-guardias-cierre',
  templateUrl: './guardias-cierre.component.html',
  styleUrls: ['./guardias-cierre.component.css']
})

export class GuardiasCierreComponent implements OnInit {
  historialCierres: CierreGuardia [] = [];
  ultimo: CierreGuardia;
  nuevoCierre: CierreGuardia;
  nuevoMotivo: Motivo;
  hayMensaje = false;
  mensaje = '';
  loading = false;
  error = false;
  errorGuardiaAct = false;
  success = false;
  modalVisible = false;
  editar = false;
  cierreEditar: CierreGuardia;
  motivoEditar: Motivo;
  cambios = false;
  guardiaAbierta: boolean;
  primeraGuardia = false;

  constructor( 
    private cierreGuardiaService: CierresGuardiaService,
    private cdr: ChangeDetectorRef,
  ) { }

  ngOnInit(): void {
    this.getHistorialCierresGuardia();
  }

  async getHistorialCierresGuardia() {
    this.loading = true;
    try {
      const data = await this.cierreGuardiaService.getHistorialCierresGuardia().toPromise();
      if (data.elementos && data.elementos.length > 0) {
        this.primeraGuardia = false;
        this.historialCierres = data.elementos;
        this.ultimo = this.historialCierres[0];
        if (this.ultimo.estado === 'ACT') {
          this.guardiaAbierta = false;
        } else {
          this.guardiaAbierta = true;
        }
      } else {
        this.primeraGuardia = true;
        this.guardiaAbierta = true;
      }
    } catch (error) {
      console.log(error);
      this.handleError();
    } finally {
      this.loading = false;
    }
  }
  

  handleError(){
    this.resetAlerts();
    this.error = true;
    this.hayMensaje = true;
    this.mensaje = 'Ocurrió un error, intente más tarde.';
  }

  handleSuccess (mensaje: string){
    this.resetAlerts();
    this.hayMensaje = true;
    this.mensaje = mensaje;
    this.success = true;
    this.cdr.detectChanges();
    this.getHistorialCierresGuardia();
  }

  handleInfo(mensaje: string){
    this.resetAlerts();
    this.errorGuardiaAct = true;
    this.hayMensaje = true;
    this.mensaje = mensaje;
  }

  resetAlerts() {
    this.hayMensaje = false;
    this.mensaje = '';
    this.loading = false;
    this.error = false;
    this.success = false;
    this.cambios = false;
    this.errorGuardiaAct = false;
  }

  async finalizarCierre(){
    await this.getHistorialCierresGuardia();
    if (!this.guardiaAbierta){
      this.ultimo.fechaHasta = this.formatDate();
      this.ultimo.estado = 'INA';
      this.setCierreDeGuardia(JSON.stringify(this.ultimo), "Guardia abierta exitosamente");
    } else {
      this.handleInfo("La guardia ya estaba abierta");
    }
  }

  async cerrarGuardia(){
    await this.getHistorialCierresGuardia();
    if (this.guardiaAbierta){
      this.openModal();
    } else {
      this.handleInfo("Ya existe un cierre de guardia activo");
    }
  }

  async editarMotivo(cierre: CierreGuardia) {
    await this.getHistorialCierresGuardia();
    if (!this.guardiaAbierta){
      this.motivoEditar = cierre.motivo;
      this.editar = true;
      this.cierreEditar = cierre;
      this.openModal();
    } else {
      this.handleInfo("La guardia ya estaba abierta")
    }
  }

  openModal(){
    this.modalVisible = true;
    this.resetAlerts();
  }

  formatDate(): string {
    return moment().format('DD/MM/YYYY HH:mm:ss');
  }

  closeModal(event: boolean) {
    this.modalVisible = event;
    this.loading = true;
    if (this.cambios){
      if (!this.editar){
        this.handleNuevoCierre();
      } else {
          this.handleEditarCierre();
      }
    }
    this.editar = false;
    this.loading = false;
  }

  recibirMotivo(event:  Motivo ) {
    if (event){
      if (this.editar){
        this.motivoEditar = event;
      } else {
        this.nuevoMotivo = event;
      }
      this.cambios = true;
    }
  }

  async setCierreDeGuardia(body: string, mensaje: string){
    await this.cierreGuardiaService.setCierreDeGuardia(body).toPromise().then(
      data => {
        this.handleSuccess(mensaje);
      },
      error => {
        this.handleError();
      }
    );
  }

  closeAlert() {
    this.resetAlerts();
  }

  handleNuevoCierre(){
    this.nuevoCierre = {
      id: null,
      fechaDesde: this.formatDate(),
      fechaHasta: null,
      fechaCarga: this.formatDate(),
      estado: 'ACT',
      motivoDescripcion: this.nuevoMotivo.descripcion,
      motivo: this.nuevoMotivo
    };
    this.setCierreDeGuardia(JSON.stringify(this.nuevoCierre), "Guardia cerrada exitosamente");
  }

  handleEditarCierre(){
    this.cierreEditar.motivo = this.motivoEditar;
    this.cierreEditar.motivoDescripcion = this.motivoEditar.descripcion;
    this.setCierreDeGuardia(JSON.stringify(this.cierreEditar), "Cierre de guardia modificado correctamente")
  }
  
}

